.amount-paid {
  position: relative;
}

.dollar-sign {
  position: absolute;
  left: 0.7rem; /* Adjust the padding as needed */
  top: 50%;
  transform: translateY(-50%);
}

.payment-history-modal input[type='number'] {
  padding-left: 1.8rem;
}

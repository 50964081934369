@font-face {
  font-family: 'BrownStd';
  src: local('BrownStd'), url(../fonts/BrownStd.woff) format('woff');
}

:root {
  --primary--color: #f46868;
}

* {
  font-family: 'Poppins', sans-serif;
  font-size: 400;
}

.highlight-today .rdtToday,
.highlight-today .rdtToday:hover {
  background-color: #8898aa !important;
  color: white !important;
}

.rdtDay.rdtActive,
.rdtDay.rdtActive:hover {
  background-color: #7f7fc1 !important;
}

@media (min-width: 1200px) {
  .loginForm .container {
    max-width: 1250px;
  }
}

.loginForm .mt-n18 {
  margin-top: -20rem !important;
}

.authBackground,
.bg-info-bh {
  background: transparent
    linear-gradient(90deg, #3176a7 0%, #205072 49%, #153253 100%) 0% 0%
    no-repeat padding-box;
}

.bg-info-bh-dark {
  background: #205072 !important;
}

.bg-default-login {
  background-color: #ffffff;
}

.body-container {
  background-color: #efefef;
}

.fill-default-login {
  fill: #f8f8fe;
}

.default-color,
.icon-color {
  color: #293b92;
}

.active.nav-item .nav-link-text {
  color: #3176a7;
}

.nav-link-text {
  color: black;
}

.icon-color-light {
  color: #8898aa;
}

.black-color {
  color: black;
}

.default-button-background,
.default-button-background:hover,
.btn-info:not(:disabled):not(.disabled):active {
  background-color: #3176a7;
  border-color: #3176a7;
}

.primary-button,
.primary-button:hover {
  background-color: #f46868;
  border-color: #f46868;
  border-radius: 10px;
  color: white;
}

.secondary-button,
.secondary-button:hover {
  background-color: white;
  border-color: #f46868;
  color: #76767b;
  border-radius: 10px;
}

.modal-body .react-datepicker-wrapper {
  width: 100%;
}

.navbar-expand-end {
  flex-flow: row nowrap;
  justify-content: flex-end;
}

.navbar-brand-img {
  max-height: 3rem !important;
}

.sidenav .navbar-brand {
  padding: 0 !important;
}

.navbar-expand-space {
  justify-content: space-between !important;
}

.table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table-header .header-left {
  width: 40%;
}
.table-header .header-right {
  display: flex;
  justify-content: flex-end;
  width: 60%;
  align-items: center;
}

.export-container {
  display: flex;
  gap: 1.2rem;
  align-items: center;
}

.search-input {
  position: relative;
}

.search-input input,
.react-datepicker__input-container input,
.PhoneInputInput,
.form-control {
  background-color: white;
  height: 2.5rem;
  border-radius: 8px;
  padding-left: 3rem;
  border: 1px solid #becad6 !important;
  color: black;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
}

.profile-img-large {
  width: 10rem;
  height: 10rem;
}

.dropdown-divider {
  margin: 0.3rem 0 !important;
}

.PhoneInputInput,
.form-control {
  padding-left: 1rem;
}

.filter-date .react-datepicker__input-container input {
  width: 15.12rem;
}

.react-datepicker__input-container input {
  padding-left: 1rem !important;
  cursor: pointer;
}

.search-input .search-icon {
  position: absolute;
  left: 1rem;
  top: 0.5rem;
}

.search-input input:focus {
  outline: none !important; /* Remove the default outline */
  border-color: #becad6 !important;
}

.table-header {
  font: normal 500 1.1rem Poppins, sans-serif;
}

.css-iuba1a-ButtonBase {
  background: transparent !important;
}

.css-ekmwv9-control {
  display: none !important;
}

.table-header .table-title {
  color: #32325d;
  font-weight: 600;
  font-size: 1.2rem;
}

.table-header .export {
  color: #f46868;
}

.table-responsive {
  overflow-x: unset !important;
}

.react-datepicker__navigation.react-datepicker__navigation--years.react-datepicker__navigation--years-upcoming {
  transform: rotate(312deg);
}

.react-datepicker__navigation.react-datepicker__navigation--years.react-datepicker__navigation--years-upcoming::before {
  border-color: black;
  border-style: solid;
  border-width: 3px 3px 0 0;
  content: '';
  display: block;
  height: 9px;
  position: absolute;
  top: 50%;
  right: 50%;
  width: 9px;
}

.react-datepicker__navigation.react-datepicker__navigation--years.react-datepicker__navigation--years-previous {
  transform: rotate(134deg);
}

.react-datepicker__navigation.react-datepicker__navigation--years.react-datepicker__navigation--years-previous::before {
  border-color: black;
  border-style: solid;
  border-width: 3px 3px 0 0;
  content: '';
  display: block;
  height: 9px;
  position: absolute;
  top: 50%;
  right: 50%;
  width: 9px;
}

.table-responsive table {
  border-bottom: none;
}

.vertical-align-icon {
  vertical-align: text-top;
}

.table-responsive td {
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: 0.83rem;
  color: #525f7f;
  font-weight: 500;
  text-align: center;
}

.table-responsive th {
  font-size: 0.8rem !important;
  padding-top: 1rem !important;
  color: #8898aa;
  letter-spacing: 1.15px !important;
  font-weight: 500;
  text-align: center;
}

.table-responsive th.name-field {
  text-align: left;
}

.dropdown-menu-item {
  color: #76767b;
  font-weight: 400;
  font-size: 0.9rem;
}

.custom-footer {
  border-top: none;
}

.user-info-cell {
  display: flex;
  gap: 0.6rem;
  align-items: center;
  padding-left: 1.4rem;
}

.profile-bottom {
  background: #f6f9fc 0% 0% no-repeat padding-box;
  border-radius: 10px;
}

.disabled-input {
  background-color: #f2f3f5 !important;
}

.small-icon {
  width: 1.5rem;
  height: 1.5rem;
}

.xsmall-icon {
  width: 1rem;
  height: 1rem;
}

.calendar-icon {
  top: 0.7rem;
  right: 0.8rem;
  pointer-events: none;
}

.clock-icon {
  top: 0.5rem;
  right: 0.5rem;
  pointer-events: none;
}

.primary-color {
  color: #f46868;
}

*::placeholder {
  color: gray !important;
  font-size: medium;
}

.required-color {
  color: #eb001b !important;
}

.user-image img {
  width: 2.2rem;
  height: 2.2rem;
  border-radius: 50%;
  object-fit: cover;
}

.user-info-cell .username {
  text-decoration: underline;
  cursor: pointer;
}

.attendance-status {
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
}

.present.attendance-status {
  background-color: green;
}

.absent.attendance-status {
  background-color: #f46868;
}

.coverImage {
  height: 35px;
  width: 22px;
  object-fit: cover;
  border-radius: 5%;
}

/* Style for the disabled button using attribute selector */
button[disabled] div {
  /* Add a gray background color */

  /* Add a lighter text color */
  color: #a0a0a0;

  /* Add a cursor style indicating that it's not clickable */
  cursor: not-allowed;
}

.rhap_additional-controls,
.rhap_volume-controls,
#rhap_current-time {
  display: none !important;
}

.rhap_container {
  background: #f9f9f9 !important;
  border: 1px solid #dee0e4 !important;
  border-radius: 5%;
  box-shadow: none !important;
}

.rhap_main {
  flex-direction: row-reverse !important;
}

.rhap_controls-section {
  margin-top: 0 !important;
  flex: none !important;
}

.rhap_progress-indicator {
  background: white !important;
  border: 1px solid black !important;
  height: 15px !important;
  width: 15px !important;
  top: -5px !important;
  margin-left: -7px !important;
}

.rhap_progress-filled {
  background-color: #3a3a73 !important;
}

.playIcon {
  color: #3a3a73;
  font-size: small;
}

.rhap_main-controls button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content !important;
}

.rhap_total-time {
  font-size: small !important;
}

.headingColor {
  color: #3b3b3b;
  font-weight: normal;
}

.headingColor-secondary {
  color: darkblue;
}

.active.nav-item {
  font-weight: bold;
}

.navbar-vertical
  .navbar-nav
  .nav-link[data-toggle='collapse'][aria-expanded='true']:after {
  color: #f46868 !important;
}

.dataTable {
  width: 100%;
  table-layout: fixed;
}

.is-invalid .ck.ck-editor__main > .ck-editor__editable:not(.ck-focused),
.is-invalid .ck.ck-toolbar,
.dropzone.invalid-dropzone .dz-message,
.is-invalid .ql-editor.ql-blank,
.rdt.is-invalid input,
.is-invalid .select2-selection.select2-selection--single,
.is-invalid .multiDropdown {
  border-color: #fb6340 !important;
}

.ck.ck-editor__main > .ck-editor__editable:not(.ck-focused),
.ck.ck-toolbar {
  border-color: #dee2e6 !important;
}

.dashedBorder {
  border: 1px dashed #dee2e6;
}

.addBtn,
.addBtn:hover {
  color: white;
  background-color: #7f7fc1;
  border-color: #7f7fc1;
}

.dropzone .dz-button {
  color: black;
  background: white;
}

.dz-message {
  padding: 1rem;
}

.searchBox {
  width: 250px;
  border-color: #dee2e6;
}

.ql-snow .ql-tooltip::before,
.ql-action::after {
  content: '' !important;
}

.dz-preview {
  display: none;
}

.spinner-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 10000;
}

.spinner-border {
  display: block;
}

.qr-box {
  width: 100vw;
  height: 100vh;
}

.spinner-container-admin {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f8f8f8ad;
  z-index: 10000;
}

.spinner {
  left: 55%;
  top: 50%;
  z-index: 1000;
  position: absolute;
  background-color: #205072;
  padding: 5px;
  border-radius: 50%;
}

.spinner-admin {
  left: 49%;
  top: 43%;
  z-index: 1000;
  position: absolute;
}

.successToast {
  background: #76d89c !important;
  border-color: #76d89c !important;
}

.updateToast {
  background: #3a3a73 !important;
  border-color: #3a3a73 !important;
}

.errorToast {
  background: red !important;
  border-color: red !important;
}

.successToast div,
.updateToast div,
.errorToast div {
  color: white;
  font-weight: bold;
}

.w-5 {
  width: 5% !important;
}

.w-15 {
  width: 15% !important;
}

.w-10 {
  width: 10% !important;
}

.w-7 {
  width: 7% !important;
}

.w-30 {
  width: 30% !important;
}

.w-35 {
  width: 35% !important;
}

.w-20 {
  width: 20% !important;
}

.w-45 {
  width: 45% !important;
}

.w-40 {
  width: 40% !important;
}

.w-65 {
  width: 65% !important;
}

.overflowStyle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mirror {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

p {
  color: black;
}

.moodModal select,
.pageEntry select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
}

.moodModal select + i.fa {
  float: right;
  margin-top: -30px;
  margin-right: 5px;
  pointer-events: none;
  background-color: #fff;
  padding-right: 5px;
}

.dropdownIcon {
  position: absolute;
  top: 45px;
  right: 34px;
}

.new-line {
  white-space: pre;
}

.rdtPicker .rdtTimeToggle,
.rdtPicker .rdtTimeToggle:hover {
  visibility: visible !important;
  background: none !important;
}

.rdtTimeToggle::before {
  visibility: hidden !important;
}

.datePicker-invalid input {
  border-color: #fb6340;
}

.search .rdtPicker {
  left: -88px !important;
}

.page-item.active .page-link {
  z-index: 1 !important;
  background-color: #f46868;
  border-color: #f46868;
}

.rdtCounters .rdtCounter:last-child .rdtCount {
  font-size: x-small !important;
}

.close {
  opacity: 1 !important;
}

.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus {
  opacity: 1 !important;
}

.pageEntry select {
  background-color: transparent;
}

.pageEntry select + i.fa {
  margin-left: -15px;
}

.text-xxs {
  font-size: xx-small;
}

.navbar-vertical.navbar-expand-xs .navbar-nav > .nav-item > .nav-link.active {
  background-color: #fcf0f0;
  margin-right: 0;
  margin-left: 0;
  padding-left: 1.5rem;
}

.nav-link-text {
  font-weight: 400;
}

.active .nav-link-text {
  color: #f46868;
}

.thead-bh {
  background-color: #f6f9fc;
}

.searchColor {
  color: #8898aa !important;
}

.tableURL {
  text-decoration: none;
  color: black;
}

.imageViewerClose {
  position: absolute;
  right: 25px;
  top: 10px;
}

.actionDropdown .dropdown-menu .dropdown-item {
  font-size: 0.78rem;
}

.actionDropdown .dropdown-menu {
  min-width: 10rem;
}

.bt_logo {
  margin-top: -1rem;
  width: 15rem;
  object-fit: contain;
}

.react-tagsinput-tag {
  border: none;
  background-color: transparent !important;
  border-width: 1px 1px 1px 5px !important;
  border-style: solid !important;
  border-color: #e5e9ef !important;
  border-left-color: #3176a7 !important;
  border-radius: 5px !important;
  padding: 0rem 0.8rem !important;
  color: #556d7d !important;
  margin-bottom: 0 !important;
  color: #556d7d !important;
  font-weight: 500 !important;
  font-size: 1rem !important;
}

.react-tagsinput {
  border: 1px solid #dee2e6 !important;
  border-radius: 0.25rem !important;
  min-height: calc(1.5em + 1rem + 5px) !important;
  padding-bottom: 3px !important;
  padding-top: 3px !important;
}

.react-tagsinput--focused .react-tagsinput-input {
  font-size: 0.875rem !important;
  color: black !important;
  width: 100% !important;
  font-family: 'Poppins', sans-serif !important;
}

.react-tagsinput-remove {
  color: black !important;
}

.forget-pass {
  color: #63839a;
}

.forget-pass-text {
  color: #adb5bd;
}

.back-login {
  color: #153253;
  font-weight: bolder;
}

.subhead {
  font-size: 1.5rem;
  font-weight: bold;
}

@media (min-width: 1200px) {
  .separator-skew {
    height: 74px;
  }
}

.signout {
  color: #d83443;
}

.adminImg {
  height: 3.5rem;
}

.itemsPerPage {
  color: #556d7d;
}

.selectItemsPerPage {
  border-color: #e5e5e5;
}

.main-dark-color {
  color: #205072;
}

.action-bg {
  color: black !important;
}

.action-bg:hover,
.action-bg:focus {
  box-shadow: none !important;
  background-color: #f3f7fa !important;
}

.dataTable .btn-icon-only.btn-sm {
  width: 2.5rem;
  height: 2.5rem;
}

.backBtn {
  width: 6rem;
  object-fit: contain;
}

.backBtnLarge {
  width: 9rem;
  object-fit: contain;
}

.select2-container--open .select2-dropdown--below {
  box-shadow: 0px 10px 20px #a8a8a861;
}

.select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  background: #c2d7e633 0% 0% no-repeat padding-box;
  color: #3176a7;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: black;
}

.linkText {
  color: #3176a7;
}

.multiDropdown,
.my-dropdown {
  width: fit-content;
  min-width: 200px;
  border: 1px solid #becad6;
  border-radius: 8px;
  background-color: transparent !important;
}

.icon-filter {
  display: inline-block;
  width: 15px;
  height: 15px;
}

.selectedLabel {
  border-width: 1px 1px 1px 3px;
  border-style: solid;
  border-color: #e5e9ef;
  border-left-color: #f46868;
  border-radius: 5px;
  width: fit-content;
  display: inline;
  padding: 0.1rem 0.31rem;
  color: #556d7d !important;
  font-size: 0.8rem;
}

.selectedClose {
  width: 1rem;
}

.css-9gakcf-option {
  background-color: transparent !important;
  color: #3176a7 !important;
}

.css-yt9ioa-option:active,
.css-1n7v3ny-option:active,
.css-1n7v3ny-option {
  background-color: transparent !important;
}

input[type='checkbox'] {
  accent-color: #f46868;
}

.css-1n7v3ny-option label,
.css-yt9ioa-option label {
  font-size: medium !important;
}

.dropdown-label {
  color: #42526e !important;
}

.sidenav-toggler-line {
  background-color: #3176a7;
}

.css-14el2xx-placeholder {
  color: transparent !important;
}

.unitList {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 10px 40px 100px #00000026;
  border-radius: 10px;
  z-index: 100;
}

.unitsListClose {
  width: 1rem;
}

.menuDropdown {
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 0 0 1px hsla(218, 50%, 10%, 0.1),
    0 4px 11px hsla(218, 50%, 10%, 0.1);
  margin-top: 8px;
  position: absolute;
  z-index: 2;
  width: 100%;
}

[class$='MenuList'] {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.topbar nav {
  background-color: white !important;
}

.profile-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: black;
  font-weight: 600;
}

.profile-container .profile-picture {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background-color: white;
}
.profile-picture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.profile-container .username {
  color: black;
  font-weight: 500;
  font-size: 1rem;
}

.account-dropdown-item-name {
  color: #3c3c43;
}

.profile-nested .profile-picture {
  align-self: flex-start;
}
.profile-info-container {
  display: flex;
  flex-direction: column;
  font-weight: 400;
  color: #3c3c43;
}

.profile-info-container .username {
  margin-bottom: 0.1rem;
  color: #3c3c43;
  letter-spacing: 0.15mm;
}

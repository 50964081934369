.horizontal-ruler {
  width: 100%;
  height: 1.25px;
  background-color: #0000001a;
  position: absolute;
  left: 0;
  top: 33.5%;
}

.auth-title {
  color: #3c3c43 !important;
  font-size: 1.5rem;
  font-weight: 500;
  margin-top: 5rem;
  margin-bottom: 1.5rem;
}

.login-button {
  background-color: var(--primary--color) !important;
  border-color: var(--primary--color);
  box-shadow: 4px 4px 6px #32325d1c;
  font-size: 1rem;
}
.login-button:hover {
  border-color: var(--primary--color);
  opacity: 0.8;
}

.login-button h3 {
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
}

.remember-me {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 1rem;
}

.remember-me svg {
  fill: white;
  cursor: pointer;
}

.forget-password-text,
.remember-me-text {
  font-weight: 400 !important;
  text-decoration: underline;
  color: #76767b !important;
  font-size: 0.9rem;
}

.remember-me-text {
  text-decoration: none;
  cursor: pointer;
  margin-left: 0.4rem;
}

.lock-icon img {
  margin-left: -5px;
}

.envelope-icon {
  opacity: 0.8;
}

.email-input,
.password-input {
  position: relative;
}
.email-input input,
.password-input input {
  padding-left: 2.3rem;
}

.auth-form .focused .form-control {
  border-color: transparent !important;
  outline: none;
}

.auth-form .form-control:focus {
  border-color: transparent !important;
}

.auth-form input {
  border-color: transparent !important;
  box-shadow: 0px 1px 3px #32325d26;
}

.email-input .envelope-icon {
  position: absolute;
  top: 27%;
  left: 3%;
  opacity: 0.7;
}

.envelope-icon i {
  opacity: 0.7;
}

.password-input .lock-icon {
  position: absolute;
  left: 3%;
  top: 22%;
}

.eye-icon {
  position: absolute;
  position: absolute;
  right: 3%;
  top: 23%;
}

.eye-icon i {
  opacity: 0.5;
}

.back-to-login p {
  color: #3c3c43;
  font-weight: 400;
  font-size: 0.9rem;
}

.back-to-login .signin-text {
  color: #f46868;
  font-weight: 400;
}

.forgotpassword-footer {
  margin-top: 1.5rem;
}

.instructions p {
  color: #3c3c43;
  font-weight: 400;
  font-size: 0.9rem;
}

.instructions-container .instructions-title {
  padding-top: 1rem;
}

.instructions-container .horizontal-ruler {
  top: 30%;
}
